/* eslint-disable no-undef, react/prop-types */
import React, { Component } from 'react';
import { css } from 'react-emotion';
import { graphql, navigate } from 'gatsby';
import Link from 'gatsby-link';
import BackgroundImage from 'gatsby-background-image';
import { Helmet } from 'react-helmet';
import { Formik } from 'formik';

import { PaperBox, Flex } from '../components/Layout';
import PageWrapper from '../components/PageWrapper';
import api from '../utils/api';
import colors from '../utils/colors';

import compass from '../assets/images/compass-button.png';
import boxOpen from '../assets/images/box-open.png';
import gooseberry from '../assets/images/gooseberry-and-waves.png';
import waves from '../assets/images/waves-bottom.png';

const buttonClass = css`
  background-color: transparent;
  border: 1px solid;
  border-radius: 5px;
  margin: 5px;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const buttonContainer = css`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 50px;
`;

const top = css`
  padding-top: 40px;
  text-align: center;
  @media screen and (max-width: 600px) {
    padding-top: 70px;
    }
`;

const fullWidth = css`
  width: 100%;
`;

class RegisterFindComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      enquired: false,
      message: null,
      boxes: 0,
      type: null,
      translation: null,
      pending: false,
      awaiting: false,
    };
  }

  componentDidMount() {
    Array.from(document.forms, (form) => {
      form.reset();
    });

    api.refresh()
    .then(res => {
      if (res.status >= 400) {
        throw new Error('The server is down, please try later');
      }
      return (res.json());
    })
    .then((returned) => {
      this.setState({message: returned.message});
      if(returned.result) {
        if(returned.result.treasure) {
          this.setState({boxes:
            returned.result.treasure.filter(
              (trinket) => trinket.type == 'box'
            ).length
          });
        }

        this.setState({
          pending: !!returned.result.email_pending,
          awaiting: +returned.result.sent && !+returned.result.arrived
        });

      }
    })
  }

  render() {
    return (
    (this.state.enquired && !this.state.message) ?
      <div onClick={() => location.reload()}>
        { this.state.type == 'box' &&
          <>
            <p className={top}>Your song fragment translates as <em>{this.state.translation}</em>.</p>
            <p>Thank you for taking part in the Quest, you’re doing so well!</p>
            <p>Keep searching, there are more Sea Gooseberry song fragments out there to be found.</p>
            <p>You could even shelter a Sea Gooseberry and reunite it with its friends at</p>
            <h1>THE GATHERING</h1>
            <img src={gooseberry} alt="Sea Gooseberry and waves" className={fullWidth}/>
            <p>On Sunday 19th December 2021 at 3.30pm</p>
            <p>Sunny Sands, Folkestone</p>
            <p>There are no guarantees, but the more committed you are to the Quest, the more likely you'll be to get a magical visitor.</p>
            <p>Fill in your details so they can find you on the <a href="/you">YOU</a> page.</p>
            <img src={waves} alt="waves" className={fullWidth}/>
          </>
        }

        { this.state.type == 'sg' &&
          <>
            <p className={top}>You're so lucky! A Sea Gooseberry has chosen to reveal itself to you! </p>
            <p>Please take good care of it and bring it along to </p>
            <h1>THE GATHERING</h1>
            <img src={gooseberry} alt="Sea Gooseberry and waves" className={fullWidth}/>
            <p>On Sunday 19th December 2021 at 3.30pm, Sunny Sands, Folkestone </p>
            <p>Please share your details on the <a href="/you">YOU</a> page so the Oracles know who is taking care of this Sea Gooseberry.</p>
            <p>If you can't attend the Gathering, please put this Sea Gooseberry back where you found it, or pass it on to someone who can come - it's vital all the Sea Gooseberries are at the Gathering or the magic won't work.</p>
            <img src={waves} alt="waves" className={fullWidth}/>
          </>
        }
      </div>

    :
      <Formik
        initialValues={{keyword: ''}}

        onSubmit={(values, {setSubmitting}) => {
          this.setState({enquired: false});
          this.setState({message: null});
          api.found(values)
          .then(res => {
            if (res.status === 400) {
              throw new Error('The server is down, please try later');
            }
            return (res.json());
          })
          .then((returned) => {
            this.setState({enquired: true});
            this.setState({message: returned.message});
            if(returned.result && returned.found) {
                this.setState({boxes: returned.result.treasure.length});
                this.setState({type: returned.found.type});
                this.setState({translation: returned.found.translation});
                window.scrollTo(0, 0);
            }
            setSubmitting(false);
          })
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleReset,
          handleBlur,
          handleSubmit,
          isSubmitting,
          /* and other goodies */
        }) => (
          <>
            <h1>Found a Song Fragment?</h1>

            { !!this.state.pending &&
              <p>Please check your inbox to confirm your email address.  (Check your spam folder if you can't see the email.)  Or reset your email on the <Link to="/you">YOU</Link> page.</p>
            }

            { !!this.state.awaiting &&
              <p>A Sea Gooseberry is on its way to you.  Please tick the box on the <Link to="/you">YOU</Link> page when it arrives.</p>
            }

            <p><img src={boxOpen} alt="Open treasure box" width="400"/></p>
            { this.state.boxes > 0 &&
                <p>You've found {this.state.boxes} box{this.state.boxes > 1 && "es"}</p>
            }
            <p>Find and register as many song fragments as you can.</p>
            <p>It's our best chance to save the Sea Gooseberries' magic..</p>
            <p>They need to know we are looking for them so they don't lose hope.</p>

            <form onSubmit={handleSubmit}>
              <Flex wrap={['wrap', 'wrap', 'wrap']}>
                <label className="form-field-label-code">
                  Enter Song Fragment or Gooseberry Name here:
                </label>
                <input
                  className="form-field-code"
                  type="text"
                  name="keyword"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.keyword}
                />
              </Flex>

              <div className={buttonContainer}>
                <button
                  className={buttonClass}
                  type="submit"
                  disabled={isSubmitting}
                >
                  <img alt="submit" src={compass} width="40px"/>
                  Submit
                </button>
              </div>

              { this.state.enquired && this.state.message &&
                <p>The Song Fragment you entered is not recognised</p>
              }

            </form>
          </>
        )}
      </Formik>
    );
  }
}

const imgStyle = css`
  border-radius: 5px;
`;

const bottomPadding = css`
  padding-bottom:40px;
`;

const RegisterFind = ({ data }) => {
  const mapData = data.map.childImageSharp;
  return (
    <PageWrapper>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Found - Beacons</title>
      </Helmet>

      <BackgroundImage
        Tag="section"
        className={imgStyle}
        fluid={mapData.fluid}
        backgroundColor={`#FFFFFF`}
        padding="20px"
      >
        <Flex justifyContent="center" align-items="center">
          <PaperBox
            width={[1, '80%', '60%']}
            m={['3.5rem 0', '3.5rem 0', '5.5rem auto']}
            px={[3, 3, 5]}
            py={[3, 3, 3]}
            color={colors.secondary}
            textAlign="center"
          >
            <RegisterFindComponent></RegisterFindComponent>
          </PaperBox>
        </Flex>
      </BackgroundImage>
    </PageWrapper>
  );
};

export const query = graphql`
query {
  map: file(relativePath: { eq: "map.jpeg" }) {
    childImageSharp {
      fluid(maxWidth: 1000) {
        ...GatsbyImageSharpFluid
      }
    }
  }
  allContentJson {
        edges {
            node {
                index {
                    title
                    aboutText
                }
      }
    }
  }
}
`;

export default RegisterFind;
